<template>
  <div class="container">
    <alert
      v-if="feature"
      type="info"
      message="New feature! Shows all the brands available that will be assigned in our articles."
      @close="feature = false"></alert>
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} brands</span>
      <span
        v-else
        data-test="txt-search-result"
        class="text-truncate">Found {{meta.total}} brands that match '{{query}}'
      </span>
      <router-link
        v-if="can(uiPermissions.BRANDS_CREATE)"
        to="/brands/new"
        data-test="btn-new-brand"
        class="btn btn-primary ml-auto">
        New brand
      </router-link>
    </div>
    <div class="row">

      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table data-test="table-brands" class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th class="w-1"></th>
                  <th>Name</th>
                  <th>Description</th>
                  <th class="text-center">Created at</th>
                  <th class="w-1">Last updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-center">
                    <span class="avatar" :style="`background-image: url(${identicon(item.id)});`"></span>
                  </td>
                  <td>
                    <div>
                      <router-link :to="`/brands/${item.id}`">{{item.name}}</router-link>
                    </div>
                  </td>
                  <td>{{item.description}}</td>
                  <td class="text-center">
                    {{moment.utc(item.created_at).local().fromNow()}}
                  </td>
                  <td>
                    {{moment.utc(item.updated_at).local().fromNow()}}
                  </td>
                  <td class="text-center">
                    <div v-if="can([uiPermissions.BRANDS_VIEW])" class="item-action dropdown">
                      <a
                        tabindex="0"
                        data-toggle="dropdown"
                        data-test="link-dropdown"
                        class="icon"><i class="fe fe-more-vertical"></i></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <router-link
                          v-if="can(uiPermissions.BRANDS_VIEW)"
                          :to="`/brands/${item.id}`"
                          data-test="link-edit"
                          class="dropdown-item">
                          <i class="dropdown-icon fe fe-edit"></i> Edit
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="6">There are no results matching your criteria.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {brands} from '@/services';

export default {

  components: {

    Pagination,
  },
  mixins: [

    list,
  ],
  data() {
    return {

      feature: true,
    };
  },
  methods: {

    fetchData(page) {
      return brands.getByParameters({

        limit: this.limit,
        page,
        query: this.query,
      });
    },
    async handleDelete(item) {
      if (window.confirm('Deleting brand cannot be undone. Are you sure that you want to delete this brand?')) {
        await brands.deleteById(item.id);
        this.refresh();
      }
    },
  },
};

</script>
